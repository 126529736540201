import React from 'react'

//Components
import Product from '../Product/Product'

//MUI
import { Grid } from '@mui/material'

const ProductsList = ({ data }) => {
  return (
    <Grid container spacing={3}>
      {
        data.map((res) => (
          <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
            <Product res={res} />
          </Grid>
        ))
      }
    </Grid>
  )
}

export default ProductsList