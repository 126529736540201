import React, { useEffect, useRef } from 'react'

//Router dom
import { Route, Routes, useLocation } from 'react-router-dom'

//MUI
import { createTheme, ThemeProvider } from '@mui/material'

//Components
import Index from './Components/Index/Index';
import NotFound from './Components/NotFound/NotFound';
import TerminosYCondiciones from './Components/Avisos/TeminosYCondiciones/TerminosYCondiciones';
import AvisoPrivacidad from './Components/Avisos/AvisoPrivacidad/AvisoPrivacidad';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1536,
    },
  },
});

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const App = () => {

  const location = useLocation();
  useScrollToTop();

  return (
    <ThemeProvider theme={theme}>
      {location.pathname === '/' ? null : <Header />}
      <Routes>
        <Route path='/' element={<Index />}></Route>
        <Route path='/terminos-y-condiciones' element={<TerminosYCondiciones />}></Route>
        <Route path='/aviso-de-privacidad' element={<AvisoPrivacidad />}></Route>
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
      {location.pathname === '/' ? null : <Footer />}
    </ThemeProvider>
  )
}

export default App