import React, { useState, useEffect } from 'react'

//Utils
import { CustomFetch } from '../../Utils/CustomFetch'
import { ProductData } from '../../Data/ProductData'
import ProductsList from '../ProductsList/ProductsList'

const ProductsListContainer = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
    CustomFetch(ProductData)
      .then(res => {
        setData(res)
        setLoading(true)
      })
  }, [])

  return (
    <>
      {
        loading
          ?
          <ProductsList data={data} setData={setData} />
          :
          <>Loading...</>
      }
    </>
  )
}

export default ProductsListContainer