const base_url = process.env.REACT_APP_SERVER_API; // servidor en Node.js

// peticiones
const request = (endpoint, obj, method = 'GET') => {
    const url = `${base_url}/${endpoint}`;
    if (method === 'GET')
        return fetch(url, { method });
    else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(obj)
        });
    }
};

export {
    request
};