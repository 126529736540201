import React from 'react'

import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material'

const Sidebar = ({ sidebar, setSidebar, aboutRef, productsRef, pvRef, contactRef }) => {

    const handleAboutRef = () => {
        aboutRef.current?.scrollIntoView({ block: "center" });
        setSidebar(false)
    };

    const handleProductsRef = () => {
        productsRef.current?.scrollIntoView({ block: "start" });
        setSidebar(false)
    };

    const handlePvRef = () => {
        pvRef.current?.scrollIntoView({ block: "center" });
        setSidebar(false)
    };

    const handleContactRef = () => {
        contactRef.current?.scrollIntoView({ block: "start" });
        setSidebar(false)
    };

    const closeSidebar = () => {
        setSidebar(false)
    }

    return (
        <Drawer
            open={sidebar}
            onClose={closeSidebar}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleAboutRef}>
                            <ListItemText primary="Sobre nosotros" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleProductsRef}>
                            <ListItemText primary="Productos" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handlePvRef}>
                            <ListItemText primary="Puntos de venta" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleContactRef}>
                            <ListItemText primary="Contacto" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}

export default Sidebar