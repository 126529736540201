import React from 'react'

import logo from '../../Assets/Images/logo-VitaNovo.png'

//MUI
import { AppBar, Box, Button, Toolbar } from '@mui/material';

//Router dom
import { Link } from 'react-router-dom';

const Header = () => {

    return (
        <AppBar position="sticky" sx={{ bgcolor: '#ffffff' }}>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                    <Link to='/'>
                        <img src={logo} alt={logo} width='90' />
                    </Link>
                </Box>
                <Box>
                    <Button LinkComponent={Link} to='/' sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }}>Inicio</Button>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header