import React, { useRef, useState } from 'react'

import pv_1 from '../../Assets/Images/Mercado-Livre-Logo.png'
import pv_2 from '../../Assets/Images/Amazon-Logo.png'
import pv_3 from '../../Assets/Images/Logo_Nefropolis.png'
import about from '../../Assets/Img/sobre_nosotros1.png'

import banner_desktop from '../../Assets/Img/2000x1000-vitanovo-.jpg'
import banner_tablet from '../../Assets/Img/1690x900-vitanovo.jpg'
import banner_mobile from '../../Assets/Img/1080x920-vitanovo-.jpg'

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";

import './Index.css'

//MUI
import { Container, Grid, Typography, Box, Stack, IconButton, AppBar, Button, Toolbar, useMediaQuery } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { FaHandHoldingUsd, FaRegHandshake, FaLeaf } from 'react-icons/fa'
import ProductsListContainer from '../ProductsListContainer/ProductsListContainer'

import logo from '../../Assets/Images/logo-VitaNovo.png'
import Footer from '../Footer/Footer'
import Sidebar from '../Sidebar/Sidebar'

const Index = () => {

    const aboutRef = useRef(null);
    const productsRef = useRef(null);
    const pvRef = useRef(null);
    const contactRef = useRef(null);

    const handleAboutRef = () => {
        aboutRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const handleProductsRef = () => {
        productsRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const handlePvRef = () => {
        pvRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const handleContactRef = () => {
        contactRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const [sidebar, setSidebar] = useState(false)

    const handleSidebar = () => {
        setSidebar(true)
    }

    const matches = useMediaQuery('(max-width: 600px)')

    return (
        <>

            <AppBar position="sticky" sx={{ bgcolor: '#ffffff' }}>
                <Toolbar>
                    <Box sx={{ display: { xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block' } }}>
                        <IconButton
                            size="large"
                            edge="start"
                            sx={{ color: 'black', fontWeight: '600', mr: 2 }}
                            onClick={handleSidebar}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: { xl: 'flex-start', lg: 'flex-start', md: 'flex-start', sm: 'flex-end', xs: 'flex-end' } }}>
                        <img src={logo} alt={logo} width='90' />
                    </Box>
                    <Box sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }, gap: '12px' }}>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} onClick={handleAboutRef}>Sobre nosotros</Button>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} onClick={handleProductsRef}>Productos</Button>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} onClick={handlePvRef}>Puntos de venta</Button>
                        <Button sx={{ color: 'black', fontWeight: '600', textTransform: 'none' }} onClick={handleContactRef}>Contacto</Button>
                    </Box>
                </Toolbar>
            </AppBar>

            <div className='banners-desktop'>
                <img src={banner_desktop} alt={banner_desktop} width='100%' height='100%' />
            </div>

            <div className='banners-tablet'>
                <img src={banner_tablet} alt={banner_tablet} width='100%' height='100%' />
            </div>

            <div className='banners-mobile'>
                <img src={banner_mobile} alt={banner_mobile} width='100%' height='100%' />
            </div>

            <Container maxWidth='lg' sx={{ my: 10 }}>
                <Grid container spacing={4} sx={{ textAlign: 'center' }}>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} sx={{ mx: 'auto' }}>
                        <div className="circle">
                            <FaLeaf className='circle-icon' />
                        </div>
                        <Typography variant='body1' sx={{ mt: 2, fontWeight: '400' }}>Vegan friendly.</Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} sx={{ mx: 'auto' }}>
                        <div className="circle">
                            <FaHandHoldingUsd className='circle-icon' />
                        </div>
                        <Typography variant='body1' sx={{ mt: 2, fontWeight: '400' }}>Cuidamos tu economía.</Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} sx={{ mx: 'auto' }}>
                        <div className="circle">
                            <FaRegHandshake className='circle-icon' />
                        </div>
                        <Typography variant='body1' sx={{ mt: 2, fontWeight: '400' }}>Comprometidos con tu salud.</Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='lg' sx={{ my: 10 }} ref={aboutRef}>
                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }}>
                        <Typography variant='h4' sx={{ fontWeight: '600' }} paragraph>Sobre Nosotros</Typography>
                        <Typography variant='body1'>
                            <i><b>Vitanovo</b></i> es una marca mexicana, la cuál nace con la finalidad de traer productos al mercado
                            enfocados en atender las necesidades de personas con enfermedad renal.
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }}>
                        <img src={about} alt={about} width='100%' style={{ borderRadius: '5px' }} />
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='xl' sx={{ my: 10 }} ref={productsRef}>
                <Typography sx={{ fontSize: '32px', fontWeight: 'bold', mb: 4 }} align='center'>Nuestros productos</Typography>
                <ProductsListContainer />
            </Container>

            <Box sx={{ my: 10, bgcolor: '#EFEFEF', py: 5 }} id='productos' ref={pvRef}>
                <Container maxWidth='xl'>
                    <Typography sx={{ fontSize: '32px', fontWeight: 'bold' }} align='center'>Puntos de venta</Typography>
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            400: {
                                slidesPerView: 1,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                            700: {
                                slidesPerView: 3,
                            },
                            1000: {
                                slidesPerView: 3,
                            },
                        }}
                        spaceBetween={20}
                        navigation={{
                            nextEl: '.best-selling-swiper-button-next',
                            prevEl: '.best-selling-swiper-button-prev',
                        }}
                        loop={true}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        modules={[Autoplay, Navigation]}
                        style={{ textAlign: 'center' }}
                    >
                        <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
                            <img src={pv_1} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
                        </SwiperSlide>

                        <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
                            <img src={pv_2} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
                        </SwiperSlide>

                        <SwiperSlide style={{ margin: 'auto 0 auto 0' }}>
                            <a href='https://www.nefropolis.com/tienda/' target='_blank' rel="noopener noreferrer">
                                <img src={pv_3} alt="" width='250' height='250' style={{ objectFit: 'contain' }} />
                            </a>
                        </SwiperSlide>
                    </Swiper>
                    {
                        matches
                            ?
                            <Stack
                                direction='row'
                                spacing={1.2}
                                justifyContent="center"
                                alignItems="center"
                                sx={{ my: 'auto' }}
                            >
                                <IconButton className='best-selling-swiper-button-prev' size='small'>
                                    <ChevronLeftIcon />
                                </IconButton>
                                <IconButton className='best-selling-swiper-button-next' size='small'>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Stack>
                            :
                            <></>
                    }
                </Container>
            </Box>

            <Box ref={contactRef}>
                <Footer />
            </Box>

            <Sidebar sidebar={sidebar}
                setSidebar={setSidebar}
                aboutRef={aboutRef}
                productsRef={productsRef}
                pvRef={pvRef}
                contactRef={contactRef}
            />
        </>
    )
}

export default Index