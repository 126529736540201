import bicarbonato from '../Assets/Img/bicarbonato-500mg_1.png'

import proteina_vainilla from '../Assets/Img/proteina-vegeatal-500-vainilla_1.png'

import proteina_chocolate from '../Assets/Img/proteina-vegeatal-500-choco_1.png'

import probiotico from '../Assets/Img/probiotico_1.png'

export const ProductData = [
    {
        id: 1,
        name: 'Bicarbonato de sodio 500mg',
        description: '',
        price: 0,
        principal_img: bicarbonato,
        link: 'https://www.nefropolis.com/tienda/producto/bicarbonato-de-sodio-vitanovo?variant=07503035883646'
    },
    {
        id: 2,
        name: 'Proteína vegetal sabor vainilla',
        description: '',
        price: 0,
        principal_img: proteina_vainilla,
        link: 'https://www.nefropolis.com/tienda/producto/proteina-vegetal-vitanovo?variant=07503035883677'
    },
    {
        id: 3,
        name: 'Proteína vegetal sabor chocolate',
        description: '',
        price: 0,
        principal_img: proteina_chocolate,
        link: 'https://www.nefropolis.com/tienda/producto/proteina-vegetal-vitanovo?variant=07503035883660'
    },
    {
        id: 4,
        name: 'Probiótico',
        description: '',
        price: 0,
        principal_img: probiotico,
        link: 'https://www.nefropolis.com/tienda/producto/probiotico-vitanovo?variant=7503035883684'
    },
]