import { basicMsg } from '../Helpers/messages';
import { request } from '../Helpers/requestHelper';

export const contactForm = async(model) => {
    try {
        const resp = await request('form/contact', model, 'POST');
        const body = await resp.json();
        if (body.status) {
            basicMsg('success', '¡Gracias!',
            'Se ha ha enviado tu mensaje');
            return true;
        } else {
            if (body.msg === 'server-err')
                basicMsg('error', 'Contacto',
                'Incidente al enviar su mensaje');
            else basicMsg('warning', 'Contacto',
                'No se pudo enviar su mensaje');
        }
        return false;
    } catch (err) {
        basicMsg('error', 'Contacto',
        'No se ha podido enviar su mensaje');
        return false;
    }
};