import React from 'react'

//MUI
import { Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Product = ({ res }) => {
  return (
    <Card sx={{ width: '100%', border: 0, boxShadow: 'none' }}>
      <CardActionArea LinkComponent={Link} to={res.link} target='_blank'>
        <CardMedia
          component="img"
          sx={{ backgroundSize: 'contain' }}
          image={res.principal_img}
          alt={res.principal_img}
        />
        <CardContent>
          <Typography variant='body1' align='center'>{res.name}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default Product